@import 'styles/variables';
@import 'styles/mixins.scss';

.container {
  flex: 1;
  min-height: calc(100vh - #{$headerHeight});
  display: flex;

  @include respond-to('small') {
    flex-direction: column;
  }
}
