@import 'styles/variables';

:global #feedContainer {
  max-height: 100%;

  .event > .label {
    order: 2;

    & + .content {
      margin: 0;
    }
  }
}

.feedEvent {
  cursor: default;
  margin-bottom: 20px !important;
}

.baseColor {
  color: darkgray !important;
}

.greenColor {
  color: $green !important;
}

.yellowColor {
  color: $yw !important;
}

.redColor {
  color: $red !important;
}
