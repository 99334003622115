@import 'styles/variables';

.card {
  border-radius: 5px;
  border: 2px solid gray;
  cursor: pointer;
  display: inline-flex !important;
  flex-basis: 300px;
  flex-direction: column;
  height: 100px;
  margin: 10px 20px !important;
  padding: 20px;
  position: relative;
}

.newCard {
  border: 2px solid $blue;
}
.ywCard {
  border: 2px solid $yw;
}
.redCard {
  border: 2px solid $red;
}
.newTitle {
  color: $blue;
}
.ywTitle {
  color: $yw;
}
.redTitle {
  color: $red;
}

.head {
  width: 100%;
  display: flex;
}

.top {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: bold;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: lightgray;
  float: left;
  margin-right: 10px;
}

.description {
  position: absolute;
  bottom: 10px;
  left: 60px;
  height: 20px;
  width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:global .ribbon {
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;

  span {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    width: 100px;
    display: block;
    position: absolute;
    top: 19px;
    right: -21px;

    &.new {
      background: $blue;
    }
  }
}
