.biometricsContainer {
  display: flex;
  flex-wrap: wrap;
}

.biometricsOnHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.biometricsItem {
  display: flex;
  align-items: center;
}

.highValue {
  color: #d25155 !important;
}

.mediumValue {
  color: #efa25a !important;
}

.lowValue {
  color: #589c88 !important;
}

.noneValue {
  color: darkgray !important;
}
