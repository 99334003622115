.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .message {
    width: 75%;
    min-width: 300px;
    display: flex;

    .reason {
      width: 60%;
    }

    .icon {
      height: 150px;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: solid 1px black;
    }
  }
}
