#notification {
  display: flex;
  flex-direction: column;
  box-shadow: none;
  color: black;
}

#actionHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#icon {
  font-size: 1.5em;
}

#cta {
  margin-left: auto;
}

:global .actionForm {
  margin-top: 20px;
  width: 100%;

  .column {
    position: relative;

    .ui.labeled.input {
      width: 100%;
      margin-bottom: 10px;

      .ui.label {
        font-size: 12px;
        padding: 5px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50px;
      }

      input {
        width: calc(100% - 50px);
      }
    }

    textarea {
      height: 100%;
    }
  }

  .bodyColumn .field {
    height: 100%;
  }
}

#bottomControls {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
