@import 'styles/variables';
@import 'styles/mixins.scss';

.covidHeader {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 115px);
  grid-auto-rows: 80px;

  @include respond-to('small') {
    flex-direction: column;
  }

  & > div:first-child > div,
  & > div:nth-child(2) > div {
    flex-direction: column-reverse;
  }

  .field {
    display: flex;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
    position: relative;
    @include respond-to('small') {
      width: 100%;
      justify-content: space-between;
    }
    padding: 15px;
    min-width: 350px;
    .fieldText {
      display: flex;
      flex-direction: column;
      font-size: 1.2rem;
      width: 100%;

      span:nth-of-type(1) {
        color: #808080;
      }

      span:nth-of-type(2) {
        color: black;
      }

      .fieldLabel {
        margin-bottom: 10px;
      }
      .fieldDropdown {
        border: none;
        font-size: 1.1rem;
        font-weight: bold !important;
        padding-top: 0;
        height: 20px;
        min-height: 20px;
        min-width: 140px;
        & > i {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        & > div > div {
          font-size: 1.1rem;
        }
      }
    }

    .biometricMainField {
      flex-direction: column;
      font-size: 1.6rem;
      padding-top: 0.45rem;
    }
    .mainField {
      flex-direction: column-reverse;
      font-size: 1.6rem;

      & > div:first-child {
        margin: 0;
        display: flex;
        align-items: center;
      }

      & > div:last-child {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
      }
    }
  }

  .fieldBiometrics {
    align-self: start;
    grid-area: 1 / 3 / 4;
    padding-top: 30px;
  }

  .passportContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .passportInfo {
      & div:first-child {
        margin-bottom: 8px;
      }

      .setByText {
        font-size: 1.1rem;
        color: darkgray;
      }
    }

    .passportActionsContainer {
      display: flex;
      flex-direction: column;
      & > div:nth-child(2) {
        margin-bottom: 16px;
      }
    }

    .passportEditContainer {
      display: flex;
      justify-content: flex-end;
    }

    .passportButton {
      height: 30px;
      font-size: 0.9rem;
    }
  }

  .locationFieldButton {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}

.covidFooter {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  p {
    color: #545454;
    font-size: 1.1rem;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
}

.covidSection {
  margin-bottom: 40px;
}

.timeAtHome {
  .barsContainer {
    display: flex;
    justify-content: space-between;
    height: 200px;
    margin-bottom: 40px;

    .bar {
      background-color: #eeeeee;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex: 1 1 auto;
    }

    .barFiller {
      background-color: #56acbf;
      position: absolute;
      width: 100%;
    }

    .barLabel {
      color: white;
      z-index: 1;
      margin-bottom: 8px;
    }

    .barEmptyLabel {
      color: black;
    }

    .barDateLabel {
      position: absolute;
      bottom: -24px;
      color: gray;
    }
  }

  .effectivenessContainer {
    background-color: #eeeeee;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.5rem;
    padding: 16px 8px;

    .effectivenessPercentage {
      font-weight: bold;
      font-size: 2.5rem;
    }
  }
}

.barSeparator {
  height: 100%;
  width: 8px;
}

.peopleInteracted {
  .barsContainer {
    display: flex;
    height: 200px;
    .bar {
      background-color: #eeeeee;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 16px;
      width: 25%;

      .barValue {
        font-weight: bold;
        font-size: 2.5rem;
      }

      .barLabel {
        font-size: 1.5rem;
      }
    }

    & > div:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.otherLocations {
  .rowContainer {
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-bottom: 1px solid #eeeeee;
  }

  .header {
    color: gray;
    padding-bottom: 8px;
  }

  .row {
    padding: 8px 0;

    & p:first-child {
      margin: 0;
    }
  }

  .locationContainer {
    display: flex;
    align-items: center;
  }

  .locationIcon {
    &:hover {
      cursor: pointer;
    }
  }
}

.highValue {
  color: #d25155;
}

.mediumValue {
  color: #f3ce7c;
}

.lowValue {
  color: #589c88;
}

.noneValue {
  color: darkgray;
}

.covidHeaderContainer {
  padding-top: 1rem !important;
}
.covidHeaderRow {
  &.underlined {
    border-bottom: 2px solid lightgrey;
    padding-bottom: 0.5rem !important;
  }
  &.title {
    margin-top: 1rem !important;
    font-size: 1.4rem;
  }
}

.covidHeaderCol {
  &.title {
    font-size: 1.4rem;
    display: flex !important;
    flex-direction: row !important;
    align-items: baseline !important;
  }
  &.hightLight {
    font-size: 1.8rem !important;
  }
  &.healthPassport {
    justify-content: space-between;
  }
  .iconLabel {
    margin-right: 0.5rem;
    &.highValue {
      color: #d25155;
    }

    &.mediumValue {
      color: #f3ce7c;
    }

    &.lowValue {
      color: #589c88;
    }

    &.noneValue {
      color: darkgray;
    }
  }
}

.dropdownFieldv2 {
  margin-top: 5px;
  &.quarantine {
    margin-top: 0px;
  }

  > div:nth-of-type(1) {
    padding: 0px !important;
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    padding-left: 0.4rem !important;
    border-radius: 10px !important;
    height: auto !important;
  }
}
.passportActionsContainer {
  display: flex;
  flex-direction: column;
  & > div:nth-child(1) {
    margin-bottom: 10px;
  }
}
