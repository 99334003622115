.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bodyContainer {
  min-width: 500px;
  width: 800px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
  &.glucareTenant {
    align-items: flex-start;
  }
}

.logoContainerMultiple {
  margin-bottom: 40px;
}

.logo {
  width: 500px;
}

.logoMultiple {
  width: 400px;
  margin-right: 40px;
}

.tenantImage {
  width: 400px;
}

.formContainer {
  width: 500px;
  max-width: 500px;
}

.azureButton {
  margin-top: 8px;
}
