@import 'styles/variables';

:global #root {
  height: 100%;
}

.mainAppContainer {
  padding-top: $headerHeight;
  height: 100%;
  overflow: auto;
}
