@import 'styles/variables';
@import 'styles/mixins.scss';

.lastAnswersBox {
  margin: 20px auto;
  width: 100%;
}

.TitleAndModal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div:nth-last-of-type(1) {
    margin: 0px !important;
  }
}
.feedEvent {
  margin: 20px auto !important;
  white-space: pre-wrap;
}
.dropdownBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  > div {
    margin: 5px;

    @include respond-to('medium') {
      margin: 5px auto;
      height: auto !important;
    }
  }
  @include respond-to('medium') {
    flex-direction: column;
  }
}

.surveyHeaderBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.surveyPaginationBox {
  display: flex;
  justify-content: flex-end;
  margin: 10px auto;
}
.surveyListBox {
  margin: 15px auto;
}
