@import 'styles/variables';
@import 'styles/mixins';

.headerTitle {
  display: flex;
  align-items: baseline;
  div:nth-of-type(1) {
    margin: 0px !important;
  }
  p {
    margin-left: 5px;
  }
}

.tableBody {
  -ms-overflow-style: none;
  max-height: 40vh;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @include respond-to('small') {
    max-height: 70vh;
  }
}

.headerFilters {
  display: flex !important;
  justify-content: space-evenly;
  align-content: center;
}

.headerSeccion {
  display: flex;
  justify-content: space-between;
  align-content: center;
  @include respond-to('medium') {
    flex-direction: column;
  }
  > div:nth-of-type(1) {
    align-self: center;
    flex: 1;
  }
  > div:nth-of-type(2) {
    height: auto !important;
    max-width: 70% !important;
  }
}
.openDetails {
  color: #526b9e;
  cursor: pointer;
  &:hover {
    color: blue;
  }
}
.detailsHeader {
  display: flex;
  align-items: center;
  margin: 1rem 0px;
  > div {
    margin: 0px !important;
  }
}
.detailsCol {
  padding: 1rem 0px;
}
.encounterNotesSection {
  width: 100%;
  margin: 5px;
  .noteBox {
    margin: 1rem auto;
  }
  .userComment {
    border: 1px solid #b3b1b1;
    background-color: #f2f2f2;
    color: #545454;
    padding: 15px;
    border-radius: 5px;
  }
  .noteOwner {
    margin: 0;
    flex: 1;
    .patientNote {
      color: #51ada8;
    }
    .clinicianNote {
      color: #526b9e;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .saveNoteButton {
      margin: 5px;
    }
  }
}
.statusIcon {
  cursor: pointer;
}
