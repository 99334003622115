.cardContainer {
  border: 2px solid #eeeeee;
  padding: 16px;
  max-width: 300px;
  border-radius: 10px;
  font-size: 1.2rem;
  min-height: 210px;

  &:hover {
    cursor: pointer;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      font-weight: bold;
      margin-left: 8px;
    }

    .bandIcon {
      margin-left: 8px;
      fill: #d2d2d2;
    }

    .bandGreen {
      fill: #589c88;
    }

    .bandYellow {
      fill: #efa25a;
    }

    .bandRed {
      fill: #d25155;
    }
  }

  .bodyContainer {
    margin: 16px 0;
    color: #5b5b5b;
    position: relative;

    .metricsContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 4px;
      margin: 8px 0;
    }

    .otherValueContainer {
      margin: 4px 0;
    }
  }

  .bodyLoader {
    top: 20%;
  }

  .footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
  }
}

.cardRed {
  background-color: #fef7f7;
  border: 2px solid #f9d4d6;
}

.highValue {
  color: #d25155;
}

.mediumValue {
  color: #efa25a;
}

.lowValue {
  color: #589c88;
}

.noneValue {
  color: darkgray;
}
