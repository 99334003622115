@import 'styles/variables';
@import 'styles/mixins';

.container {
  flex: 1;
  height: calc(100vh - #{$headerHeight});
  display: flex;

  @include respond-to('medium') {
    flex-direction: column;
    min-height: auto;
    height: auto;
  }
}
.buttonsHeaderBox {
  display: flex;
  justify-content: flex-end;
}
.nameContainer {
  display: flex;
  align-items: center;
  margin: 16px 0 24px;

  & > h2 {
    margin: 0 0 0 8px !important;
  }
}

.infoSection {
  padding: 16px 8px;
  .accordionData {
    @include respond-to('medium') {
      margin: 0 auto;
      width: fit-content;
    }
  }
}
.scrollableSection {
  overflow-y: scroll;
  max-height: 35rem;
}
.patientDocumentsTitle {
  background-color: #2668c4;
  color: #f9f9f9 !important;
  &:hover {
    background-color: transparent;
    color: #2668c4 !important;
  }
  &.active {
    color: #2668c4 !important;
  }
}
.diseasesProgressBox {
  display: flex;
  justify-content: space-between;
  .diseasesProgressList {
    width: 50%;
  }
  > div:not(:first-child) {
    margin-left: 36px;
  }
}

.leftInfoContainer {
  position: fixed;
  width: 400px;

  @include respond-to('medium') {
    position: initial;
    width: 100%;
  }
}

.patientInfo {
  flex: 0 0 $sidebarWidth;
  min-width: 400px;
  max-width: 400px;
  height: calc(100vh - #{$headerHeight});
  padding: 20px;
  margin-left: 1px;
  border-right: 2px solid lightgray;

  @include respond-to('medium') {
    position: relative;
    flex-direction: column;
    height: auto;
    max-width: 100%;
    border: none;
  }
}

.editButton {
  position: absolute;
  top: 16px;
  right: 16px;
}

.saveButtonContainer {
  display: flex;
  justify-content: flex-end;
  .saveButton {
    margin-right: 8px !important;
  }
}

.patientTabbedInfo {
  width: 100%;
  min-width: 800px;
  > div:nth-of-type(1) {
    @include respond-to('medium') {
      flex-direction: column !important;
    }
  }
  @include respond-to('medium') {
    min-width: 300px;
  }
}

.patientMainContainer {
  height: 100%;
  padding: 30px;
  display: flex;
  width: 100%;
  margin-left: 400px;
  margin-right: calc(20% + 30px);
  overflow: scroll;

  @include respond-to('regular') {
    margin-right: calc(20% + 60px);
  }

  @include respond-to('medium') {
    margin-left: 0;
    overflow: unset;
  }
}

.patientFeedContainer {
  float: right;
  padding: 30px;
  min-width: 300px;
  max-width: 600px;
  position: fixed;
  width: 20%;
  right: 0;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 150px;
  background: white;

  @include respond-to('medium') {
    position: initial;
    width: initial;
    right: unset;
    overflow: unset;
    padding-bottom: 0;
  }
}

.secondaryInfo {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  & > div:nth-child(-n + 2) {
    border-top: 1px solid $lightGray;
  }
}

.secondaryInfoDivider {
  border-bottom: 1px solid $lightGray;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 48%;
  & > input {
    width: 65%;
  }
}

.secondaryInfoLabel {
  color: $softGray;
  font-weight: bold;
}

.secondaryInfoValue {
  text-overflow: ellipsis;
  text-align: left;
}

.secondaryInfoSelect {
  & :nth-child(2) {
    height: 43px !important;
  }
  div {
    border-radius: 0.28571429rem !important;
  }
}

.heightField {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.patientCard {
  overflow-y: scroll;
  height: calc(100vh - #{$headerHeight} - 550px);
  @include respond-to('medium') {
    display: flex;
    overflow: auto;
    justify-content: space-around;
    flex-wrap: wrap;
    height: initial;
  }
}

.patientCardData {
  margin-top: 20px;
  @include respond-to('medium') {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.patientCardDataLabel {
  text-transform: uppercase;
  color: darkgray;
  display: block;
}

.patientCardDataValue {
  padding-right: 5px;
  color: gray;
  white-space: pre-line;
}

.reportModalContent {
  display: flex !important;
  flex-direction: column;
  align-items: center;

  #ck_editor2 {
  }
}

.editorModal {
  height: calc(80vh - 10em);

  .editorContent {
    height: 100%;

    & > div:nth-child(2) {
      height: 100%;

      & > div {
        height: 100%;

        & > div {
          height: 90% !important;
        }
      }
    }
  }
}

.pdfViewerContainer {
  & > div {
    display: flex;
    justify-content: center;
  }

  .paginationContainer {
    height: auto;

    & canvas {
      margin: auto;
    }
  }
}

:global .patientFeedContainer {
  flex: 2;

  > div {
    padding: 30px;
    height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
    position: relative;
    display: flex;
    flex-direction: column;
  }
}
.accordionTitle {
  text-transform: capitalize;
}
.accordionData {
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  .patientCardData {
    @include respond-to('medium') {
      width: 100%;
    }
  }
}
.associatedData {
  text-transform: capitalize;
  border: none !important;
  margin: 15px 0em;
}

.datePickerBox {
  margin: 10px auto;
  border: 1px solid #dbdbdb;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin-right: 10px;
  }
  div {
    border: none !important;
    flex: 1;
    input {
      cursor: pointer !important;
    }
  }
}
.mealImage {
  width: 60%;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
}

.mealTimelineBox {
  display: flex;
  height: 37vh;
  align-items: stretch;
  .mealTimelinePreview {
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    flex: 1;
    margin-right: 2px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    &.previewShadow {
      box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.2);
    }
  }
  .mealTimelineChart {
    flex: 3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: white;
    overflow: hidden;
    svg {
      overflow: visible !important;
      background-color: white;
      circle {
        fill: #51b46e;
        width: 5px;
        opacity: 0.5;
        cursor: pointer;
        &.selectedMeal {
          fill: #2f52ec;
          stroke: #4e5ea7;
        }
      }
      text {
        fill: #545454;
      }
      .yText {
        fill: #f46157;
        text-anchor: middle;
      }
      .axis {
        line {
          stroke: #d7d7d7;
        }
        path {
          stroke: #d7d7d7;
        }
      }
    }
  }
}
.hoursPickerBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.singleMealbox {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .mealImage {
    width: 40%;
    margin-bottom: 15px;
  }
  .singleMealSection {
    width: 100%;
    margin: 5px;
    .userComment {
      border: 1px solid #b3b1b1;
      background-color: #d7d7d7;
      color: #545454;
      padding: 15px;
      border-radius: 5px;
    }
    .noteOwner {
      margin: 0;
      flex: 1;
      .patientNote {
        color: green;
      }
      .clinicianNote {
        color: blue;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .saveNoteButton {
        margin: 5px;
      }
    }
  }
  .mealTabInfo {
    width: 100%;
  }
  .addMetadataButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5px;
  }

  .metadataCard {
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    margin: 5px auto;
    padding: 5px;
    .metadataInfo {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
    }
    .metadataField {
      min-width: 30%;
      padding: 5px;
      margin: 0;
    }
    .metadataNotes {
      padding: 5px;
      width: 100%;
    }
    h4 {
      margin: 10px 5px;
      text-decoration: underline;
    }
  }
}

.metadataOtherField {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}
.leftButtonBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  .publishedCheckBox {
    display: flex;
    align-items: center;
    b {
      margin-right: 5px;
    }
    .checkbox {
      input:checked + label,
      input:checked:focus + label {
        &::before {
          background-color: #51b46e !important;
        }
      }
    }
  }
  button {
    margin-left: 10px !important;
  }
}
.noMargin {
  margin: 0;
}

.singleAppointment {
  table {
    .openDetails {
      color: #4e5ea7;
      cursor: pointer;
      &:hover {
        color: blue;
      }
    }
  }
}
.appointmentSection {
  padding: 16px 8px;
  overflow-x: auto;
  table {
    .openDetails {
      color: #4e5ea7;
      cursor: pointer;
      &:hover {
        color: blue;
      }
    }
    .dateValue,
    th {
      font-size: 0.85em !important;
      word-break: normal !important;
    }
    td {
      word-wrap: break-word !important;
    }
  }
}
.appointmentTableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdownAppointment {
  > div {
    height: auto !important;
  }
}
.locationControlContainer {
  display: flex;
  > section:not(:first-child) {
    margin-left: 8px;
  }
}

.locationControlSection {
  display: flex;
  flex-direction: column;
}

.locationControlLabel {
  margin: 0 5px;
  color: #888;
  font-size: 12px;
}

.radiusControlSection {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.radiusControlLabel {
  margin: 5px;
  color: #888;
  font-size: 12px;
}

.locationControlButton {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.locationMapContainer {
  height: 500px;
  margin-top: 16px;
}

.pickerHeaders {
  display: flex;
  justify-content: space-between;
  > h3 {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
}
.modalLoaderBox {
  > div:nth-of-type(1)::before {
    border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
  }
  > div:nth-of-type(1)::after {
    border-color: #767676 transparent transparent !important;
  }
}
.availableDaysBox {
  overflow: auto;
  max-height: 30vh;
}
.availableDayTitle {
  display: flex;
  justify-content: space-between;
  .availableDayTitleCircles {
    display: flex;
  }
}
.emrConfigureLink {
  cursor: pointer;
  color: #4183c4;
  &:active {
    text-decoration: underline;
  }
}
.linkBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.linkBtn:hover,
.linkBtn:focus {
  text-decoration: none;
}

.contactSupportButton {
  width: 100%;
  margin-top: 1rem !important;
}
.patientCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  h3:nth-of-type(1) {
    margin: 0;
  }
}
