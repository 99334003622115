.custom-chat-title {
  background: #578ea9;
  border-radius: 0.3rem;
  color: white;
  cursor: pointer;
  height: 2rem;
  font-weight: bold;
  text-align: center;
  width: 50.2rem;
}

.hide-custom-chat-title {
  background: #578ea9;
  border-radius: 0.3rem;
  color: white;
  cursor: pointer;
  bottom: 0;
  position: absolute;
  height: 2rem;
  left: 78%;
  width: 20%;
  right: auto;
  font-weight: bold;
  text-align: center;
  z-index: 1031;
}

.hide-chat-container {
  display: none;
}

.chat-container {
  width: 50rem;
  padding-right: 11rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
  bottom: 0;
  height: 30rem;
  right: 2rem;
  position: absolute;
  z-index: 1031;
  box-shadow: -9px 11px 18px -4px rgba(0, 0, 0, 0.75);
  background: #e2dede;
}
