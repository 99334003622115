$primaryColor: #2668c4;
$pageBackground: #fff;
$headerBackground: #fdfdfd;
$darkGray: #969696;
$softGray: #c2c1c0;
$lightGray: #edecec;
$headerTabPrimaryBackground: #f3f3f3;
$headerTabSecondaryBackground: #9b9b9b;
$headerTabTextPrimaryColor: black;
$headerTabTextSecondaryColor: white;

$blue: $primaryColor;
$yw: #f7d36f;
$red: #e25358;
$green: #589c88;

$headerHeight: 80px;
$sidebarWidth: 400px;

$heartRateLineColor: #dd439a;
$heartRateCircleColor: #dd439a;
$weightLineColor: #009688;
$weightCircleColor: #009688;
$movementColor: #ff903b;
$sleepDeepColor: #336cd6;
$sleepLightColor: #4ccfe0;
$sleepRemColor: #40a0db;
$sleepAwakeColor: #ffcf66;

$xsmall: 400px;
$small: 768px;
$medium: 1024px;
$regular: 1300px;
$regularLarge: 1500px;
$large: 1580px;
$largeWideScreen: 1650px;
$wideScreen: 2000px;
$fullScreen: 2560px;
