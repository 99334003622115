@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.navHeader {
  align-items: center;
  background-color: $headerBackground;
  border-bottom: 2px solid black;
  color: black;
  display: flex;
  flex-direction: row;
  height: $headerHeight;
  position: fixed;
  width: 100%;
  z-index: 10;

  .divider {
    flex: 1;
    @include respond-to('small') {
      display: none;
    }
  }
  .tabsView {
    @include respond-to('small') {
      display: none;
    }
  }
  .dropdownView {
    display: none;
    flex: 1;
    @include respond-to('small') {
      display: inherit;
    }
    a {
      border: none !important;
    }
  }
  a {
    align-items: center;
    border-radius: 5px;
    background-color: $headerTabPrimaryBackground;
    color: $headerTabTextPrimaryColor;
    font-size: 16px;
    font-weight: bold;
    height: 35px;
    line-height: 16px;
    margin-left: -1px;
    margin-right: 15px;
    margin-bottom: -2px;
    padding: 11px 28px 12px 22px;
    width: 50%;
    z-index: 20;

    &:hover {
      background-color: $headerTabSecondaryBackground;
      color: $headerTabTextSecondaryColor;
      button {
        background-color: $headerTabSecondaryBackground !important;
        color: $headerTabTextSecondaryColor !important;
        transition: background-color 0s !important;
      }
    }

    &[aria-current='page'] {
      color: $headerTabTextSecondaryColor;
      background-color: $headerTabSecondaryBackground;

      button {
        background-color: $headerTabSecondaryBackground !important;
        color: $headerTabTextSecondaryColor !important;
      }
    }

    button {
      background-color: $headerTabPrimaryBackground !important;
      color: $headerTabTextPrimaryColor !important;
      margin: 0 -26px 0 0 !important;
      transition: background-color 0s !important;
    }

    @include respond-to('small') {
      width: 100px;
      font-size: 12px;
      font-weight: bold;
      padding: 5px;
      position: relative;

      button {
        position: absolute;
        padding: 0;
        top: 6px;
        right: 0;
      }
    }
  }
}

.title {
  padding: 30px;
  width: $sidebarWidth;
  line-height: 16px;
  font-size: 16px;
}

.patientTabs {
  flex: 1;
  bottom: 0;
  margin-bottom: -2px;
  height: $headerHeight;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.tabText {
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  margin-right: 5px;
}

.logoutButton {
  background-color: $headerTabSecondaryBackground !important;
  margin-right: 15px !important;
}
