.noDataContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableContainer {
  height: calc(100% - 32px);
  margin: 16px 0;
}
