@import 'styles/variables';

.container {
  padding: 30px 40px;

  .bodyContainer {
    padding-top: 16px;
    font-size: 1.1rem;
    color: #333333;

    .title {
      font-size: 42px;
      color: $darkGray;
      margin-bottom: 20px;
    }

    .proximityTable {
      padding: 8px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 310px));
      grid-row-gap: 8px;
      grid-column-gap: 8px;
    }

    .loadMoreContainer {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.covidAlerts {
  padding: 10px !important;
  &.biometics {
    margin-top: 5px !important;
    margin-bottom: 0px !important;
  }
  &.test {
    padding: 11.5px !important;
  }
}
