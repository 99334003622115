@import 'styles/variables';
@import 'styles/mixins';

.buttonsHeaderBox {
  display: flex;
  justify-content: flex-end;
}

.datePickerBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.conversationActionsBox {
  display: flex;
  justify-content: space-between;
}
.container {
  margin-top: 60px;
  margin-bottom: 60px;
}

.frame {
  border: 2px solid lightgrey;
}
.summaryContainer {
  margin-top: 1rem !important;
}
.bottomFrame {
  border-bottom: 2px solid lightgrey;
}

.headerTitle {
  display: flex;
  align-items: baseline;
  div:nth-of-type(1) {
    margin: 0px !important;
  }
  p {
    margin-left: 5px;
  }
}

.diagnosisListItem {
  display: block;
  font-weight: normal;
  padding: 0em;
  margin: 0em;
  font-size: 1rem;
  line-height: 1.2em;
  color: rgba(0, 0, 0, 0.6);
}

.modalButton {
  margin-bottom: 30px;
}
.tableBody {
  -ms-overflow-style: none;
  max-height: 40vh;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @include respond-to('small') {
    max-height: 70vh;
  }
}
.headerSeccion {
  display: flex;
  justify-content: space-between;
  align-content: center;
  @include respond-to('medium') {
    flex-direction: column;
  }
  > div:nth-of-type(1) {
    align-self: center;
    flex: 1;
  }
  > div:nth-of-type(2) {
    height: auto !important;
    max-width: 70% !important;
  }
}
.columnItems {
  padding: 1rem 0px;
}
.linkedButtonsBox {
  display: flex;
  justify-content: flex-end;
}
