#vizHeader {
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
  flex-flow: column;
  & section:nth-child(2) {
    margin: 0px 16px;
  }
  .chartOptions {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    align-items: center;
    .actionButtons {
      display: flex;
      align-items: flex-end;
      height: 3em;
    }
  }
}

.tableToggle {
  padding-top: 20px;
  margin-left: 10px;
}

.vizTitle {
  margin: 0 auto 0 0;
  flex: 1;
}

.overlayToggler {
  margin: 0px 20px;
}

.vizControlLabel {
  margin: 0 5px;
  color: #888;
  font-size: 12px;
}

:global .DateRangePicker {
  min-width: 260px;
  .DateInput {
    width: 100px;

    input {
      font-size: 14px;
      width: 100%;
    }
  }

  .DateRangePicker_picker {
    z-index: 3;
  }
}

:global .ui.selection.dropdown {
  border-radius: 0 !important;
  height: 46px;
  font-size: 14px;
  font-weight: 200;
  line-height: 24px !important;

  .dropdown.icon {
    line-height: 24px !important;
    height: 46px !important;
  }
}

.graphContainer {
  height: 500px;
  margin-bottom: 16px;
  overflow-y: scroll;
  padding: 16px;
  & div:not(:first-child) {
    margin: 20px 0;
  }
}

.tableContainer {
  height: 500px;
  padding: 8px;
}

.noDataContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph {
  height: 100%;
  width: 100%;
}
.legendContainer {
  display: flex;
  flex-wrap: wrap;

  .legend::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 8px;
  }
  .movementLegendColor::before {
    background-color: #ff903b;
  }
  .heartRateLegendColor::before {
    background-color: #dd439a;
  }
  .weightLegendColor::before {
    background-color: #009688;
  }
  .bloodPressureLegendColor::before {
    background-color: #198dd0;
  }
  .glucoseLegendColor::before {
    background-color: #d578ff;
  }
  .insulinBasalLegendColor::before {
    background-color: #8e8e8e;
  }
  .insulinLegendColor::before {
    background-color: #307bf6;
  }
  .sleepLegendColor::before {
    background-color: #ffcf66;
  }
  .hrvLegendColor::before {
    background-color: #f31f68;
  }
  .respRateLegendColor::before {
    background-color: #aa7eff;
  }
  .temperatureLegendColor::before {
    background-color: #b6a24f;
  }
  .spo2Color::before {
    background-color: #26745b;
  }
  .batteryLevelLegendColor::before {
    background-color: #7bbd18;
  }
  .bandStatusLegendColor::before {
    background-color: #ffa8a7;
  }
}

.togglesContainer {
  margin: 16px;
}

.overlaySelector,
.daySelector {
  display: flex;
  flex-direction: column;
  & > div {
    height: auto !important;
  }
}

.trendChartContainer {
  position: relative;
}

.trendChartTitle {
  font-weight: bold;
}

.detailsSection {
  padding: 8px 16px;
  margin-top: 16px;

  .detailsInfoSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;

    .infoSection {
      width: 30%;
    }

    .middleInfoSection {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .rightInfoSection > p {
      text-align: right;
    }

    .infoValue {
      margin-bottom: 0;
      font-size: 1.3rem;
    }

    .infoLabel {
      color: darkgray;
    }
  }
}
