@import 'styles/variables';

.container {
  padding: 30px 40px;

  .bodyContainer {
    padding-top: 16px;
    font-size: 1.1rem;
    color: #333333;

    .searchContainer {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-end;
    }

    .title {
      font-size: 42px;
      color: $darkGray;
      margin-bottom: 40px;
    }

    :global #patientCountContainer {
      margin-bottom: 24px;

      .value {
        text-align: left;
        color: $blue;
        text-transform: lowercase;
        font-size: 36px !important;
        margin-bottom: 20px;

        .gray {
          color: gray;
        }
      }

      .label {
        text-align: left;
        color: gray;

        .ui.progress {
          display: inline-block;
          margin: 0 0 0 20px;
        }
      }
    }

    .proximityTable {
      max-height: 700px;
      overflow-y: scroll;
      border: 1px solid $lightGray;
      border-radius: 5px;
      padding: 8px 16px;
      min-height: 50px;
      position: relative;

      & > div:not(:first-child) {
        border-top: 1px solid $lightGray;
      }
    }
    .loadMoreContainer {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
