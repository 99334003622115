@import 'styles/variables.scss';

.logoContainer {
  min-width: $sidebarWidth;
  display: flex;
  justify-content: center;
  &.glucareTenant {
    align-items: flex-start;
  }
}

.multipleLogos {
  justify-content: space-evenly;
}

.logoType {
  width: 35%;
}

.logoSquare {
  margin: 2rem -7rem 2rem 2rem;
  width: 30%;
}

.tenantLogo {
  height: 4.5rem;
}
