#notification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: none;
  color: black;
}

#icon {
  font-size: 1.5em;
}

#cta {
  margin-left: auto;
}
