.tableRow {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr repeat(6, 1fr);
  align-items: center;
  padding: 16px 0;
}

.tableHeaderRow {
  padding: 16px;
}

.rowClickable {
  &:hover {
    cursor: pointer;
  }
}

.emptyRow {
  grid-column: 2 / -1;
  position: relative;
  height: 100%;
}

.centeredCell {
  justify-self: center;
  text-align: center;
}

.tableHeader {
  font-size: 1.2rem;
  color: gray;
}

.nameContainer {
  margin-left: 8px;
}

.highValue {
  color: #d25155;
}

.mediumValue {
  color: #efa25a;
}

.lowValue {
  color: #589c88;
}

.noneValue {
  color: darkgray;
}
